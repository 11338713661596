//httpConfig.interceptor.ts
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, take  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
  
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor() { 
    }
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add HEADER: 'content-type': 'application/json'
        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        // Add HEADER: Accept': 'application/json'
        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });

        // Add AUTHENTICATION TOKEN on qivos-cloud services
        if(request.url.includes('/qc-api/v1.0/protected')){
            const token = localStorage.getItem('x-m-jwt-token');
            
            if(token){
                request = request.clone({
                    setHeaders: { 'x-m-jwt-token': token }
                });
            }
        }

        return next.handle(request);
    }
}