import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Person, QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-fast-registration',
  templateUrl: './fast-registration.component.html',
  styleUrls: ['./fast-registration.component.scss'],
})
export class FastRegistrationComponent implements OnInit {
  termsAccepted = false;
  loading = false;
  mobileNumber = '';
  otp = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private qcService: QivosCloudService) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.mobileNumber = params.get('mobile');
      this.otp = params.get('otp');
    });
  }

  onRegisterClick(){
    this.loading = true;
    const person = Person.fromMobileRegistration({
      telephoneNumber: this.mobileNumber,
      countryCode: 'gr',
      languageCode: 'el',
      schemaCode: '1111'
    });

    person.loyaltyMembershipData[0].registrationSource = 'PACKAGE_RECYCLING_FORM ';

    this.qcService.createPersonByTelephone(person, this.otp, 'QR_RECYCLING_FORM_OTP')
      .subscribe(
        success => {
          this.loading = false;

          // Go to result page
          const memberQCCode = success.payload?.data?.loyaltyMembershipData[0]?.QCCode;
          this.router.navigate(['/landing/result', { memberQCCode: memberQCCode}]);
        },
        _ => {
          this.loading = false;
          this.alertService.showErrorGeneralAlert();
        }
    );
  }

  onCancelClick(){
    this.router.navigate(['/landing']);
  }
}
